<template>
  <ul class="accordion">
    <accordion-item v-for="(faq, idx) in faqBase" :key="idx">
      <template #header>
        <span v-text="faq.question"></span>
      </template>
      <template #content>
        <div v-html="faq.answer"></div>
      </template>
    </accordion-item>
  </ul>
</template>

<script setup>
import AccordionItem from './AccordionItem.vue';

const faqBase = [
  {
    question: 'Question 1',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit eu, amet diam consectetur eu ut. Sed id eget platea diam nisl tortor vestibulum, ullamcorper. Venenatis, sit tortor et urna, libero morbi amet nunc. Amet eu senectus praesent nulla.Sagittis senectus viverra tellus elementum diam vitae dignissim.Nisl consectetur posuere varius sem massa sed egestas.Enim faucibus vitae sodales vel sollicitudin ante.Sed interdum eu nunc eget tellus'
  },
  {
    question: 'Question 2',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit eu, amet diam consectetur eu ut. Sed id eget platea diam nisl tortor vestibulum, ullamcorper. Venenatis, sit tortor et urna, libero morbi amet nunc. Amet eu senectus praesent nulla.Sagittis senectus viverra tellus elementum diam vitae dignissim.Nisl consectetur posuere varius sem massa sed egestas.Enim faucibus vitae sodales vel sollicitudin ante.Sed interdum eu nunc eget tellus'
  },
  {
    question: 'Question 3',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit eu, amet diam consectetur eu ut. Sed id eget platea diam nisl tortor vestibulum, ullamcorper. Venenatis, sit tortor et urna, libero morbi amet nunc. Amet eu senectus praesent nulla.Sagittis senectus viverra tellus elementum diam vitae dignissim.Nisl consectetur posuere varius sem massa sed egestas.Enim faucibus vitae sodales vel sollicitudin ante.Sed interdum eu nunc eget tellus'
  },
];
</script>

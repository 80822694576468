<template>
  <div class="tab_wrapper">
    <div class="tab_field_wrapper">
      <div class="tab_field" style="background-color: #FFFFFF" v-for="(tab, i) in tabBase" :key="i">
        <div class="open_tab" @click="closeAlltab(tab)">
          <h3>{{ tab.q }}
            <i class="fa-solid fa-plus" :class="tab.open ? 'rotate'
            : ''"></i>
          </h3>
        </div>
        <p class="invisible" :class="tab.open ? 'visible' : ''">
          {{ tab.a }}
        </p>

      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'Tabs',

  setup() {
    const tabBase = ref([]
    )
    const closeAlltab = (item) => {
      if (!item.open) {
        tabBase.value.forEach(el => el.open = false)
      }
      item.open = !item.open
    }
    return { tabBase, closeAlltab }
  }
};
</script>
<style scoped>
.tab_wrapper {
  text-align: center;
}

.tab_field {
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  border-radius: 3px;
  padding: 18px 24px;
  text-align: left;
}

.tab_field_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.invisible {
  visibility: hidden;
  opacity: 0;
  height: 0;
  text-align: left;
  transition: opacity .3s, height .3s, padding .3s, visibility .3s;
}

.visible {
  visibility: visible;
  height: auto;
  opacity: 1;
  text-align: left;
  padding-top: 24px;
}

h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-transform: initial;
}

.open_tab {
  cursor: pointer;
}

.rotate {
  transform: rotate(45deg);
}

.fa-plus {
  transition: transform 0.3s;
}
</style>
<template>
  <div v-if="data.mounted">
    <Teleport to="body">
      <modal :show="trigger_modal" :class="props.source">
        <template #header>
          <div v-if="data.modalStep === 'init'" class="modal__header">
            <div class="modal__close" @click.prevent="$emit('close')">
              <icon-close />
            </div>
            <div class="modal__title">
              <icon-cart fill="#303030" />
              <h4>
                Add order
                {{ props.order.name }} to cart
              </h4>
            </div>
            <div v-if="useCart.cartItems > 0">
              <p>
                You've currently got items in your cart. Would you like to
                replace the contents of your cart with Order
                {{ props.order.name }} or add them on top?
              </p>
            </div>
          </div>
          <div v-else-if="data.modalStep === 'validate'" class="modal__header">
            <div class="modal__close" @click.prevent="$emit('close')">
              <icon-close />
            </div>
            <div class="modal__title">
              <icon-exclamation fill="#D90606" />
              <h4>Some items are low in stock</h4>
            </div>
            <div>
              <p>
                Thanks, we’ve added the items in order {{ props.order.name }} to
                your cart. Some items from this order are lower in stock than
                the specified amount, so weren’t added to your cart. These items
                are:
              </p>
            </div>
            <div class="modal__line__items">
              <ul>
                <hr />
                <li v-for="(item, idx) in data.lowStockItems" :key="idx">
                  <p class="accent">{{ item.title }}</p>
                  <div>
                    <p class="light small">
                      Barcode: {{ item.variant.barcode }}
                    </p>
                    <p class="small">SKU: {{ item.variant.sku }}</p>
                  </div>
                  <div>
                    <p class="small">
                      Available Quantity: {{ item.variant.inventory_quantity }}
                    </p>
                    <p class="small">Original Quantity: {{ item.quantity }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-else-if="data.modalStep === 'confirm'" class="modal__header">
            <div class="modal__close" @click.prevent="$emit('close')">
              <icon-close />
            </div>
            <div class="modal__title">
              <icon-cart fill="#303030" />
              <h4>
                We've added order
                {{ props.order.name }} to your cart
              </h4>
            </div>
            <template
              v-if="data.unavailableItems.length"
              class="modal__line__items"
            >
              <div>
                <p>
                  Some items from your list are out of stock, so weren’t added
                  to your cart. These items are:
                </p>
              </div>
              <div class="modal__line__items">
                <ul>
                  <hr />
                  <li v-for="(item, idx) in data.unavailableItems" :key="idx">
                    <p class="accent">{{ item.title }}</p>
                    <div>
                      <p class="light">Barcode: {{ item.variant.barcode }}</p>
                      <p>SKU: {{ item.variant.sku }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </template>
        <template #body>
          <div class="modal__body">
            <template v-if="data.modalData.length > 0">
              <div v-if="data.modalStep === 'init'">
              <div class="modal__actions">
                <button
                  v-if="useCart.cartItems > 0"
                  @click="replaceCart"
                  class="btn__base btn__base--light"
                  :disabled="data.updating"
                >
                  <template v-if="data.replaceCartValidation.success">
                    <IconCheck />
                  </template>
                  Replace cart
                </button>
                <button
                  @click="addItemsToCart(data.stockedItems)"
                  class="btn__base btn__base--dark"
                  :disabled="data.updating"
                >
                  <template v-if="data.addedToCartValidation.success">
                    <IconCheck />
                  </template>
                  Add to cart
                </button>
              </div>
              <div class="modal__validation">
                <p v-if="data.addedToCartValidation.error" class="small error">
                  There is either nothing to add or you may already have the
                  maximum amount of items in your cart from this order.
                  Alternatively, try the replace cart option above.
                </p>
              </div>
            </div>
            <div v-if="data.modalStep === 'validate'">
              <div class="modal__note">
                <p>
                  Would you like to add the the available quantities of these
                  items to your cart?
                </p>
              </div>
              <div class="modal__actions">
                <button
                  @click="addToCart(data.stockedItems)"
                  class="btn__base btn__base--light"
                  :disabled="data.updating"
                >
                  <template v-if="data.addedToCartValidation.success">
                    <IconCheck />
                  </template>
                  Dismiss items
                </button>
                <button
                  @click="addAllItemsToCart"
                  class="btn__base btn__base--dark"
                  :disabled="data.updating"
                >
                  <template v-if="data.lowStockValidation.success">
                    <IconCheck />
                  </template>
                  Add Items
                </button>
              </div>
              <div class="modal__validation">
                <p v-if="data.addedToCartValidation.error" class="small">
                  There was an error. Please try again soon.
                </p>
              </div>
            </div>
            <div v-if="data.modalStep === 'confirm'">
              <div class="modal__actions">
                <button
                  @click="windowRedirect('/cart')"
                  class="btn__base btn__base--dark"
                  :disabled="data.updating"
                >
                  View cart
                </button>
              </div>
              <div class="modal__helper">
                <p>
                  Remember if you'd like to create recurring orders, you can
                  click the 'Create list from order' button.
                </p>
              </div>
            </div>
            </template>
            <template v-else>
              <p class="accent">There are no products that are able to be added to your cart. Items you previously ordered may either be out of stock or no longer available for you to purchase.</p>
            </template>
          </div>
        </template>
      </modal>
    </Teleport>
  </div>
</template>

<script setup>
import { reactive, onMounted, onBeforeMount } from 'vue';
import { useCustomerStore } from '../../../stores/customer';
import { useCartStore } from '../../../stores/cart';
import { clearItems } from '../../../utils/cart';
import { addItems } from '../../../utils/cart';
import { windowRedirect } from '../../../utils/helpers';
import Modal from '../Modal.vue';
import IconClose from '../icons/IconClose.vue';
import IconCheck from '../icons/IconCheck.vue';
import IconCart from '../icons/IconCart.vue';
import IconExclamation from '../icons/IconExclamation.vue';
import axios from 'axios';

const props = defineProps({
  customer: {
    type: Object,
    required: true,
  },
  order: {
    type: Object,
    required: true,
  },
  trigger_modal: {
    type: Boolean,
    required: true,
  },
  source: {
    type: String,
    required: false,
  },
});

const useCustomer = useCustomerStore();
const useCart = useCartStore();

const emit = defineEmits(['close']);

const data = reactive({
  mounted: false,
  updating: false,
  modalData: [],
  unavailableItems: [],
  lowStockItems: [],
  stockedItems: [],
  modalStep: 'init',
  validateLowStock: false,
  addedToCartValidation: {
    success: false,
    error: false,
  },
  lowStockValidation: {
    success: false,
    error: false,
  },
  replaceCartValidation: {
    success: false,
    error: false,
  },
});

const sortCartItems = async () => {
  data.modalData.forEach((item) => {
    if (!item.variant.available) {
      data.unavailableItems.push(item);
    } else if (item.quantity > item.variant.inventory_quantity) {
      data.lowStockItems.push({
        id: item.variant.id,
        quantity: item.quantity
      });
    } else {
      data.stockedItems.push({
        id: item.variant.id,
        quantity: item.quantity
      });
    }
  });
};

const replaceCart = async () => {
  data.updating = true;
  try {
    await clearItems();
    useCart.state.items = [];
    await sortCartItems();
    if (data.lowStockItems.length) {
      data.modalStep = 'validate';
    } else {
      await addToCart(data.stockedItems);
      data.modalStep = 'confirm';
    }
  } catch (err) {
    throw err;
  }
  data.updating = false;
};

const addItemsToCart = async (items) => {
  try {
    await sortCartItems();
    if (data.lowStockItems.length) {
      data.modalStep = 'validate';
    } else {
      await addToCart(data.stockedItems);
      data.modalStep = 'confirm';
    }
  } catch (err) {
    throw err;
  }
};

const addAllItemsToCart = async () => {
  const lowStockItems = [];
  data.lowStockItems.forEach((item) => {
    lowStockItems.push({
      id: item.variant.id,
      quantity: item.variant.inventory_quantity,
    });
  });
  const mergeOrderItems = [...data.stockedItems, ...lowStockItems];
  await addToCart(mergeOrderItems);
};

const addToCart = async (items) => {
  if (items.length) {
    try {
      await addItems(items);
      useCart.loadCartItems();
      data.addedToCartValidation.success = true;
      setTimeout(() => {
        data.addedToCartValidation.success = false;
      }, 1500);
      data.modalStep = 'confirm';
    } catch (err) {
      data.addedToCartValidation.error = true;
    }
  } else {
    data.addedToCartValidation.error = true;
  }
};

onBeforeMount(() => {
  try {
    props.order.line_items.forEach(async (item) => {
      const config = {
        method: 'get',
        url: `/products/${item.product.handle}?view=append`,
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios(config);
      const products = await res.data;
    });
  } catch (err) {
    throw err;
  }
});

onMounted(() => {
  data.mounted = true;
});
</script>

<template>
  <div v-if="data.mounted && data.item" class="order__detail__item">
    <div class="order__detail__item__details">
      <a
        :href="data.item.url"
        class="order__detail__item__image"
        :aria-label="data.item.product_title"
      >
        <VImg
          :media="image"
          :fixed-size="imageSize"
          :alt="data.item.product_title"
        />
      </a>
      <div class="order__detail__item__content">
        <p class="accent" v-text="itemTitle"></p>
        <div class="order__detail__item__price">
          <p class="accent small disabled">{{ money(item.product.price) }}<span> (Plus GST)</span></p>
        </div>
        <div class="order__detail__item__codes">
          <p class="light small">Code: {{ data.item.variant.barcode }}</p>
          <p class="small">SKU: {{ data.item.variant.sku }}</p>
        </div>
        <div>
          <product-status :available="data.item.variant.available" />
        </div>
      </div>
      <div class="order__detail__item__quantity">
        <p class="accent small">Quantity: {{ item.quantity }}</p>
      </div>
      <div class="order__detail__item__subtotal">
        <p class="accent small">
          {{ money(itemSubtotal) }}<span> (Plus GST)</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, computed } from 'vue';
import axios from 'axios';
import { money } from '../../utils/money';
import VImg from './VImg.vue';
import ProductQuantity from './ProductQuantity.vue';
import ProductStatus from './ProductStatus.vue';
import IconClose from './icons/IconClose.vue';

const props = defineProps({
  item: {
    type: Object,
    required: true,
    default: () => {},
  },
  customer: {
    type: Object,
    required: true,
  },
});

const settings = PS.Settings;

const data = reactive({
  mounted: false,
  item: props.item,
  listSaved: props.list_saved,
  metafields: null,
  variants: null,
});

const itemSubtotal = computed(() => {
  return props.item.product.price * props.item.quantity
});

const itemTitle = computed(() => {
  return props.item.title.replace(/&#39;/g, "'").replace(/&quot; /g, '" ')
});

const image = props.item.image
  ? {
      alt: null,
      height: 400,
      media_type: 'image',
      position: 1,
      src: props.item.image,
      width: 400,
    }
  : {
      alt: null,
      height: 400,
      media_type: 'image',
      position: 1,
      src: `https:${settings.product_fallback_image}`,
      width: 400,
    };
const imageSize = '400x';

onMounted(async () => {
  data.mounted = true;
});

</script>

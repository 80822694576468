<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 7.5C0 3.36914 3.33984 0 7.5 0C11.6309 0 15 3.36914 15 7.5C15 11.6602 11.6309 15 7.5 15C3.33984 15 0 11.6602 0 7.5ZM10.8691 6.21094C11.1914 5.88867 11.1914 5.39062 10.8691 5.06836C10.5469 4.74609 10.0488 4.74609 9.72656 5.06836L6.5625 8.23242L5.24414 6.94336C4.92188 6.62109 4.42383 6.62109 4.10156 6.94336C3.7793 7.26562 3.7793 7.76367 4.10156 8.08594L5.97656 9.96094C6.29883 10.2832 6.79688 10.2832 7.11914 9.96094L10.8691 6.21094Z"
      fill="#31BE00"
    />
  </svg>
</template>

<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8C0 3.86914 3.33984 0.5 7.5 0.5C11.6309 0.5 15 3.86914 15 8C15 12.1602 11.6309 15.5 7.5 15.5C3.33984 15.5 0 12.1602 0 8ZM5.12695 6.62305L6.50391 8L5.12695 9.37695C4.83398 9.66992 4.83398 10.1094 5.12695 10.373C5.39062 10.666 5.83008 10.666 6.09375 10.373L7.4707 8.99609L8.87695 10.373C9.14062 10.666 9.58008 10.666 9.84375 10.373C10.1367 10.1094 10.1367 9.66992 9.84375 9.37695L8.4668 8L9.84375 6.62305C10.1367 6.35938 10.1367 5.91992 9.84375 5.62695C9.58008 5.36328 9.14062 5.36328 8.87695 5.62695L7.4707 7.0332L6.09375 5.62695C5.83008 5.36328 5.39062 5.36328 5.12695 5.62695C4.83398 5.91992 4.83398 6.35938 5.12695 6.62305Z"
      fill="#D90606"
    />
  </svg>
</template>

<template>
  <Teleport to="body">
    <modal :show="trigger_modal" :class="props.source">
      <template #header>
        <div class="modal__header">
          <div class="modal__close" @click.prevent="$emit('close')">
            <icon-close />
          </div>
          <div class="modal__title">
            <h4>
              Create a new list from
              {{ props.order.name }}
            </h4>
          </div>
          <div>
            <p>
              Add the contents of this order to create a new list. Note: after
              you create the list you can remove or adjust item quantities.
            </p>
          </div>
        </div>
      </template>
      <template #body>
        <div class="modal__body">
          <p>Specify a name for your new list (optional):</p>
          <div>
            <input
              v-model="data.listName"
              type="text"
              placeholder="List name"
            />
            <button
              @click="createList"
              class="btn__base btn__base--dark"
              :disabled="data.updating"
            >
              <template v-if="data.validation.success">
                <icon-check />
                Created
              </template>
              <template v-else>
                <icon-plus />
                Create
              </template>
            </button>
          </div>
          <div
            v-if="data.validation.success || data.validation.error"
            class="modal__validation"
          ></div>
          <template v-if="data.validation.success">
            <p>
              List {{ data.apiResponse.data.name }} has been created with the
              contents of this order.
              <span @click="emit('update-account-state')" class="underline cursor-pointer"
                >View list here.</span
              >
            </p>
          </template>
          <template v-if="data.validation.error" class="modal__validation">
            <p class="small">There was an error. Please try again soon.</p>
          </template>
        </div>
      </template>
    </modal>
  </Teleport>
</template>

<script setup>
import { reactive, onMounted } from 'vue';
import { useCustomerStore } from '../../../stores/customer';
import Modal from '../Modal.vue';
import IconClose from '../icons/IconClose.vue';
import IconCheck from '../icons/IconCheck.vue';
import IconPlus from '../icons/IconPlus.vue';
import IconExclamation from '../icons/IconExclamation.vue';
import axios from 'axios';

const props = defineProps({
  customer: {
    type: Object,
    required: true,
  },
  order: {
    type: Object,
    required: true,
  },
  trigger_modal: {
    type: Boolean,
    required: true,
  },
  source: {
    type: String,
    required: false,
  },
});

const useCustomer = useCustomerStore();

const {
  PS,
  localStorage: ls,
} = globalThis;

const emit = defineEmits(['close', 'update-account-state']);

const data = reactive({
  updating: false,
  listName: '',
  newListName: null,
  lineItemPayload: null,
  apiResponse: null,
  validation: {
    success: false,
    error: false,
  },
});

const constructLineItems = () => {
  const result = props.order.line_items.map((item) =>
    Object.assign({
      pid: item.product_id,
      vid: item.product.variants[0].id,
      title: item.product.variants[0].name,
      image: item.image,
      handle: item.product.handle,
      sku: item.product.variants[0].sku,
      barcode: item.product.variants[0].barcode,
      quantity: item.quantity,
    })
  );
  data.lineItemPayload = result;
};

const createList = async () => {
  data.updating = true;
  const payload = data.listName
    ? {
        name: data.listName,
        items: data.lineItemPayload,
      }
    : {
        items: data.lineItemPayload,
      };
  const config = {
    baseURL: '/a/ps',
    method: 'post',
    url: `${props.customer.id}/wishlist/create`,
    headers: {
      'Content-Type': 'application/json',
      'Custom-Token': ls.getItem('psAppToken'),
    },
    data: JSON.stringify(payload),
  };
  try {
    const res = await axios(config);
    data.apiResponse = res.data;
    data.validation.success = true;
    setTimeout(() => {
      data.validation.success = false;
    }, 5000);
  } catch (err) {
    if (err.response.status === 400) {
      await useCustomer.psAppToken(props.customer.id);
    } else {
      data.validation.error = true;
      setTimeout(() => {
        data.validation.error = false;
      }, 5000);
    }
    throw err;
  }
  data.updating = false;
};

onMounted(() => {
  constructLineItems();
});
</script>

/**
 * imports
 */
import 'vite/modulepreload-polyfill';
import { createApp } from 'vue';
import {createPinia} from 'pinia';
import VueConf from '../plugins/piniaStore';
import './header';
import './footer';
import './index';
import './product';
import './collection';
import './search';
import './cart';
import './login';
import './account';
import './faq';
import './downloads';
import './suppliers';
import './contact';
import './trade-application';
import '../scripts/tabs';

export const pinia = createPinia();

/**
 * create vue instance function
 */
const createVueApp = () => {
  const app = createApp({});

  app.use(VueConf)

  /**
   * vue components
   * auto-import all vue components
   */
  const vueComponents = import.meta.glob('../components/**/*.vue', {
    eager: true,
  });

  for (const path in vueComponents) {
    const match = path.match(/([^/\\]+)\.vue$/);
    const name = match && match[1];
    name && app.component(name, vueComponents[path].default);
  }

  return app;
};

/**
 * create and mount vue instance(s)
 */

/**
 * fixes for Shopify sections
 * 1. properly render vue components on user insert in the theme editor
 * 2. reload the current page to rerender async inserted sections with vue components
 *
 * add the 'vue' keyword to the section's wrapper classes if the section uses any vue functionality e.g.:
 * {% schema %}
 * {
 *   "class": "vue-section"
 * }
 * {% endschema %}
 */
if (window.Shopify.designMode) {
  document.addEventListener('shopify:section:load', (event) => {
    if (event.target.classList.value.includes('vue')) {
      createVueApp().mount(event.target);
    }
  });
} else if (
  !window.Shopify.designMode &&
  process.env.NODE_ENV === 'development'
) {
  new window.MutationObserver((mutationsList) => {
    mutationsList.forEach((record) => {
      const vue = Array.from(record.addedNodes).find(
        (node) => node.classList && node.classList.value.includes('vue')
      );
      if (vue) window.location.reload();
    });
  }).observe(document.body, {
    childList: true,
    subtree: true,
  });
}

<template>
  <div class="vue-2">
    <div><h3>Hello world from Vue 2</h3></div>
    <div>Test props: {{ msg }}</div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorldVue2',

  props: ['msg'],

  data() {
    return {
      mounted: false,
    };
  },

  mounted() {
    this.mounted = true;
  },
};
</script>

<style>
.vue-2 {
  background: tomato;
}
</style>

<script setup bem-block="vpic">
import { image } from '../../utils/image';

const props = defineProps({
  breakpoint: {
    type: String,
    required: true,
    default: '--mobile-up',
  },
  mobileMedia: {
    type: [Object, String],
    required: true,
    default: () => {},
  },
  desktopMedia: {
    type: [Object, String],
    required: true,
    default: () => {},
  },
});

const emit = defineEmits(['loaded']);

const { breakpoint, mobileMedia, desktopMedia } = props;

const smallSrc = constructMobileSrcSet(mobileMedia);
const bigSrc = constructDesktopSrcSet(desktopMedia);

function constructMobileSrcSet(img) {
  const path = img ? img.src || img.originalSrc || img.url || img : null;
  if (typeof path === 'object' || !path) return null;

  let set = `${image(path, 200)} 100w`;
  if (img.width >= 200) set += `, ${image(path, 400)} 200w`;
  if (img.width >= 400) set += `, ${image(path, 800)} 400w`;
  if (img.width >= 600) set += `, ${image(path, 1200)} 600w`;
  if (img.width >= 800) set += `, ${image(path, 1600)} 800w`;
  if (img.width >= 1000) set += `, ${image(path, 2000)} 1000w`;
  if (img.width >= 1500) set += `, ${image(path, 3000)} 1500w`;
  if (img.width >= 2000) set += `, ${image(path, 4000)} 2000w`;
  if (img.width >= 4000) set += `, ${image(path, 4000)} 8000w`;

  return set;
}

function constructDesktopSrcSet(img) {
  const path = img ? img.src || img.originalSrc || img.url || img : null;
  if (typeof path === 'object' || !path) return null;

  let set = `${image(path, 800)} 400w`;
  if (img.width >= 600) set += `, ${image(path, 1200)} 600w`;
  if (img.width >= 800) set += `, ${image(path, 1600)} 800w`;
  if (img.width >= 1000) set += `, ${image(path, 2000)} 1000w`;
  if (img.width >= 1500) set += `, ${image(path, 3000)} 1500w`;
  if (img.width >= 2000) set += `, ${image(path, 4000)} 2000w`;
  if (img.width >= 4000) set += `, ${image(path, 4000)} 8000w`;

  return set;
}
</script>

<template>
  <picture class="vpic">
    <source media="--mobile-up" :data-srcset="bigSrc" />
    <source :data-srcset="smallSrc" />
    <img
      src=""
      :class="['vpic__img', 'lazyload']"
      alt="banner"
      @lazyloaded="emit('loaded')" />
  </picture>
</template>

<style lang="scss" scoped>
.vpic {
  width: 100%;

  &__img {
    width: 100%;
    height: 100%;
    display: block;
    background-color: var(--light-grey);
    background-image: var(--svg-superette);
    background-size: 15%;
    background-position: center;
    background-repeat: no-repeat;

    &.lazyloaded {
      background-color: transparent;
      background-image: none;
      transition: background-color 0.275s;
    }

    // &.lazyload:not([src]) {
    //   visibility: hidden;
    // }
  }
}
</style>

<template>
  <svg
    width="18"
    height="11"
    viewBox="0 0 18 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 1L9 9L1 0.999999" stroke="#303030" stroke-width="1.5" />
  </svg>
</template>
